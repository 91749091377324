import React from "react";
import "./MainPortfolio.scss";
import HeaderBar from "../../components/HeaderBar/HeaderBar";
import SubSectionOne from "./subSectionOne/subSectionOne";
import SubSectionTwo from "./subSectionTwo/subSectionTwo";
import MobileBottomBar from "../../components/MobileBottomBar/MobileBottomBar";
export default function MainPortfolio() {
  return (
    <div className="main-portfolio porfolio-mobile-display">
      <div className="portfolio-header u-Width90">
        <HeaderBar
          headerTitle={"Portfolio"}
          headerTitleInfo={"Resumen del Portfolio por Perfil"}
        />
      </div>

      <div className="u-displayFlex mobile-column wrap">
        <div className="">
          {" "}
          <SubSectionOne />
        </div>
        <div>
          {" "}
          <SubSectionTwo />
        </div>
      </div>

      <div>
        <MobileBottomBar />
      </div>
    </div>
  );
}
