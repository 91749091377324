import React, { useState, useEffect } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import ResetPassword from '../views/Login/ResetPassword/ResetPassword.js';
import ForgotPassword from '../views/Login/ForgotPassword/ForgotPassword.js';
import CreatePassword from '../views/Login/CreatePassword/CreatePassword.js';
import EmailConfirmation from '../views/Login/EmailConfirmation/EmailConfirmation.js';
import Login from '../views/Login/Login.js';
import Layout from '../views/Layout/Layout.js';
import { checkAuthentication } from '../services/AuthService';

export default function RouterComponent() {
  const [isAuthenticated, setIsAuthenticated] = useState(checkAuthentication());

  useEffect(() => {    
    const handleStorageChange = () => {
      setIsAuthenticated(checkAuthentication());
    };
    window.addEventListener('storage', handleStorageChange);    
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  return (
    <Routes>
      <Route path="/reset-password" element={<ResetPassword />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/create-password" element={<CreatePassword />} />
      <Route path="/email-confirmation" element={<EmailConfirmation />} />
      <Route path="/login" element={isAuthenticated ? <Navigate to="/dashboard" /> : <Login />} />
      <Route path="/" element={isAuthenticated ? <Navigate to="/dashboard" /> : <Login />} />
      <Route path="/dashboard" element={isAuthenticated ? <Layout page="dashboard" /> : <Navigate to="/login" />} />      
      <Route path="/portfolio" element={<Layout page="portfolio" />} />
      <Route path="/usuarios" element={<Layout page="usuarios" />} />
    </Routes>
  );
}
