import React from 'react';
import {
  Area,
  XAxis,
  YAxis,  
  Tooltip,
  ResponsiveContainer,
  ComposedChart,
  Line
} from 'recharts';

const data = [
    { time: 'Jan 2018', enterprise: 2, participants: 1.5, cumulative: 3.5 },
    { time: 'Jan 2019', enterprise: 4, participants: 3, cumulative: 7 },
    { time: 'Jan 2020', enterprise: 6, participants: 4.5, cumulative: 10.5 },
    { time: 'Jan 2021', enterprise: 2, participants: 1.5, cumulative: 3.5 },
    { time: 'Jan 2022', enterprise: 4, participants: 3, cumulative: 7 },
    { time: 'Jan 2023', enterprise: 6, participants: 4.5, cumulative: 10.5 },
    { time: 'Jan 2024', enterprise: 8, participants: 6, cumulative: 14 },
  ];

export default function CustomLineChart() {
  return (
    <ResponsiveContainer width="100%" height={300}>
      <ComposedChart 
        data={data}
        margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
      >
        <defs>
          <linearGradient id="colorA" x1="0" y1="1" x2="0" y2="0">
            <stop offset="5%" style={{ stopColor: 'transparent', stopOpacity: 0.15 }} />
            <stop offset="95%" style={{ stopColor: '#0096D7', stopOpacity: 0.15 }} />
          </linearGradient>          
        </defs>
        <XAxis dataKey="time"
               axisLine={false}
               tickLine={false}
        />
        <YAxis 
          tickFormatter={(value) => (value === 0 ? value : `${value} Mil`)}
          axisLine={false} 
          tickLine={false} 
        />
        
        <Tooltip />
        <Area type="monotone" dataKey="cumulative" stroke="#0096D7" fillOpacity={1} fill="url(#colorA)" />
        <Line type="monotone" dataKey="enterprise" stroke="#F14D4D" strokeDasharray="5 5" />
        <Line type="monotone" dataKey="participants" stroke="#4AA785" strokeDasharray="3 4 5 2" />
      </ComposedChart>
    </ResponsiveContainer>
  );
}
