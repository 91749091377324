import { useState } from "react";
import { useTranslation } from "react-i18next";
import "./LanguageDropdownMenu.scss"


const DropdownLanguage = () => {
  const { i18n } = useTranslation();
  const [language, setLanguage] = useState("es");
  const [openMenu, setOpenMenu] = useState(false)

  const handleLangChange = (evt) =>{
    const lang = evt.target.value;
    setLanguage(lang);
    i18n.changeLanguage(lang)
    setOpenMenu(false)
  }

  return (

    <div className="languageMenuIcon">
      {/* Incluir svg de iconos banderas para lenguages */}
      <i onClick={()=>setOpenMenu((prev)=>!prev)}>
          {language === "es" && ( 
            <img className="lngicon" src="https://cdn.countryflags.com/thumbs/spain/flag-round-250.png" alt="Spanish"/>
            
          )}
          {language === "en" && (
              <img className="lngicon" src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/88/United-states_flag_icon_round.svg/1024px-United-states_flag_icon_round.svg.png" alt="English"/>
          )}
          {language === "ptr" && (
              <img className="lngicon" src="https://upload.wikimedia.org/wikipedia/commons/thumb/4/4a/Brazilian_flag_icon_round.svg/1200px-Brazilian_flag_icon_round.svg.png" alt="Portuguese"/>
          )}
        </i>

        {openMenu &&(
            <div className="dropdown">
              <ul>
               <div className="u-displayFlex dropDownOptions">
               <option className="lang1" value="es" onClick={handleLangChange}>ES</option>
               <img className="lngIconSmall" src="https://cdn.countryflags.com/thumbs/spain/flag-round-250.png" alt="Spanish"/>
               </div>
               <div className="u-displayFlex dropDownOptions">
                <option className="lang2" value="en" onClick={handleLangChange}>EN</option>
                <img className="lngIconSmall" src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/88/United-states_flag_icon_round.svg/1024px-United-states_flag_icon_round.svg.png" alt="English"/>
                </div>
                <div className="u-displayFlex dropDownOptions">
                <option className="lang3" value="ptr" onClick={handleLangChange}>PT</option>
                <img className="lngIconSmall" src="https://upload.wikimedia.org/wikipedia/commons/thumb/4/4a/Brazilian_flag_icon_round.svg/1200px-Brazilian_flag_icon_round.svg.png" alt="Portuguese"/>
                </div>
              </ul>
            </div>
        )}
    </div>

  );
};

export default DropdownLanguage;
