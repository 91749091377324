import React from "react";
import "./MainUsuarios.scss";
import HeaderBar from "../../components/HeaderBar/HeaderBar";
import SubSectionOne from "./subSectionOne/subSectionOne";
import SubSectionTwo from "./subSectionTwo/subSectionTwo";
import MobileBottomBar from "../../components/MobileBottomBar/MobileBottomBar";
import MobileTabsMenu from "../../components/MobileTabsMenu/MobileTabsMenu";
import MobileProfileBar from "../../components/MobileProfileCard/MobileProfileBar";

export default function MainUsuarios() {
  return (
    <div className="u-displayGrid main-usuarios ">
      <div className="usuarios-header display_column ">
        <HeaderBar
          headerTitle={"Usuarios"}
          headerTitleInfo={"Resumen del Portfolio por Perfil"}
        />
      </div>

      <div className="u-displayFlex display_column wrap web-display">
        <div>
          {" "}
          <SubSectionOne />
        </div>
        <div>
          {" "}
          <SubSectionTwo />
        </div>
      </div>

      <div className="mobile-display">
        <MobileProfileBar />
        <MobileTabsMenu />
        <MobileBottomBar />
      </div>
    </div>
  );
}
