import * as React from 'react';
// import { BarChart } from '@mui/x-charts/BarChart';
// import Stack from '@mui/material/Stack';
// import { LineChart } from '@mui/x-charts/LineChart';
import { ResponsiveChartContainer } from '@mui/x-charts/ResponsiveChartContainer';
import { BarPlot } from '@mui/x-charts/BarChart';
import { LinePlot } from '@mui/x-charts/LineChart';
import { ChartsXAxis } from '@mui/x-charts/ChartsXAxis';
import  { ChartsYAxis } from '@mui/x-charts/ChartsYAxis';
import { ChartsGrid } from '@mui/x-charts/ChartsGrid';
import { ChartsLegend } from '@mui/x-charts/ChartsLegend';
import Box from '@mui/material/Box';
import { ChartsTooltip } from '@mui/x-charts/ChartsTooltip';

export default function VerticalBarsChart() {
  return (
    <div className='u-marginAm'>
      <Box sx={{ width: '100%' }}>
        <ResponsiveChartContainer
            dataset={dataset}
            series={[
              { type: 'bar', dataKey: 'depositos', layout: 'vertical', stack: 'stack', color: '#A1E3CB' },
              { type: 'bar', dataKey: 'extracciones', layout: 'vertical', stack: 'stack', color: '#F14D4D'},
              { type: 'line', dataKey: 'evolucion', color: '#2B3674' },
            ]}
            height={300}
            
            margin={{right:20}}
            {...chartSettingsV}
          >
            
            <BarPlot />          
            <LinePlot />
            <ChartsGrid vertical horizontal />
            <ChartsXAxis position="bottom" axisId="x-axis-id" disableLine disableTicks/>
            <ChartsYAxis position="left" axisId="y-axis-id" disableLine disableTicks/>          
            <ChartsLegend direction='row' />
            <ChartsTooltip/>
        </ResponsiveChartContainer>
      </Box>      
      
      <div className='u-displayFlex u-alignItems'>
        <div className='u-marginRm-12'>
          <svg width="25" height="7" >
            <g>
              <rect width="25" height="7" fill="#F14D4D"></rect>
            </g>          
          </svg>          
          <span className='u-marginHs'>Extracciones</span>
        </div>

        <div className='u-marginRm-12'>
          <svg width="25" height="7" >
            <g>
              <rect width="25" height="7" fill="#A1E3CB"></rect>
            </g>          
          </svg>          
          <span className='u-marginHs'>Depósitos</span>
        </div>

        <div className='u-marginRm-12'>
          <svg width="25" height="7" >
            <g>
              <rect width="25" height="7" fill="#2B3674"></rect>
            </g>          
          </svg>          
          <span className='u-marginHs'>Evolución</span>
        </div>
      </div>
    </div>
        
  );
}

const dataset = [
  [4, -8, -4, 'Enero 2021'],
  [0, -4, -2, 'Enero 2022'],
  [6, 0, 3, 'Enero 2023'],
  [4, 4, 4, 'Enero 2024'],
].map(([depositos, extracciones, evolucion, order]) => ({
  depositos,
  extracciones,
  evolucion,
  order,
}));

const chartSettingsV = {
    dataset,
    outerRadius: 100,
    grid: { horizontal: true, vertical: true },    
  xAxis: [{ 
    scaleType: 'band', 
    dataKey: 'order',
    id: 'x-axis-id' }],
  yAxis:[{
    scaleType: 'linear',          
    valueFormatter:(value) => `$ ${value} Mil`,
    id: 'y-axis-id',  
      
  }]
};
// eslint-disable-next-line
{/*   
  <Stack direction="column">
<Box sx={{ width: '100%' }}>
  
  <LineChart        
    dataset={dataset}        
    series={[
      {dataKey: 'evolucion', label:'evolucion'},          
    ]}        
  >        
  </LineChart>
  <BarChart        
    series={[
      { dataKey: 'depositos', label: 'depositos', layout: 'vertical', stack: 'stack' },
      { dataKey: 'extracciones', label: 'extracciones', layout: 'vertical', stack: 'stack' },
    ]}
    {...chartSettingsV}
    borderRadius='7'
  />
</Box>      
</Stack> 
slotProps: {
      legend: {        
        direction: 'row',
        position: { vertical: 'bottom', horizontal: 'left' },
        padding: -5,
        id: 'chart-legend'
      },
    },


*/}