import React from "react";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import BarChartIcon from "@mui/icons-material/BarChart";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default function MobileTabContent1({ value }) {
  let currentPercentageValue = 0;
  let matchingPercentageValue = "2.00";
  return (
    <CustomTabPanel value={value} index={0}>
      <div className="u-displayFlex u-marginAm u-justifyEvenly  u-flexWrap  ">
        <div className="base-data-card seccondary-data-card-bg u-marginAm u-justifyCenter ">
          <div className="u-felxColumn ">
            <label className="card-label">Valor Actual de la cartera</label>
            <label className="card-price">U$D 7.303.451.-</label>
            <label className="card-sub-label">
              <span className="u-color-success u-marginRs">
                {currentPercentageValue} %
              </span>
              Desde el inicio del plan
            </label>
          </div>
        </div>
        <div className="base-data-card tertiary-data-card-side-bg u-marginAm u-justifyCenter  ">
          <div className="u-felxColumn">
            <label className="card-label">Aportes totales</label>
            <label className="card-price">U$D 240,9.-</label>
          </div>
        </div>
        <div className="base-data-card default-data-card-bg u-marginAm u-justifyCenter ">
          <div className="u-displayFlex gap4">
            <div className="card-icon-container">
              <BarChartIcon />
            </div>
            <div className="u-felxColumn">
              <label className="card-label">Aporte desde incial</label>
              <label className="card-price">U$D 108,82.-</label>
            </div>
          </div>
        </div>
        <div className="base-data-card default-data-card-bg u-marginAm u-justifyCenter ">
          <div className="u-displayFlex gap5">
            <div className="card-icon-container">
              <BarChartIcon />
            </div>
            <div className="u-felxColumn ">
              <label className="card-label ">% Matching</label>
              <label className="card-price">{matchingPercentageValue}%</label>
            </div>
          </div>
        </div>
      </div>
    </CustomTabPanel>
  );
}
