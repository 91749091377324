import React from "react";
import "./subSectionTwo.scss";
import BarChartIcon from "@mui/icons-material/BarChart";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import WalletOutlinedIcon from "@mui/icons-material/WalletOutlined";
//to be used in future logic
//import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import CustomLineChart from "../../../components/MultipleLinesChart/MultipleLinesChart";
import DonutChart from "../../../components/DonutChart/DonutChart";
import CardTitleWSelect from "../../../components/CardTitleWSelect/CardTitleWSelect";
import DarkToolTip from "../../../components/ToolTip/DarkToolTip";
import HorizontalBarsChart from "../../../components/HorizontalBarsChart/HorizontalBarsChart";

export default function SubSectionTwo() {
  let currentPercentageValue = 0;
  let brandName = "Syngenta";
  return (
    <div className="sectionTwo-container">
      {/* Seccion 1 */}
      <div className="u-displayFlex u-flexWrap u-paddingAs">
        <div className="base-data-card seccondary-data-card-bg u-marginAm ">
          <div className="u-felxColumn">
            <label className="card-label">Valor Actual de la cartera</label>
            <label className="card-price">U$D 7.303.451.-</label>
            <label className="card-sub-label">
              <span className="u-color-success u-marginRs">
                {currentPercentageValue} %
              </span>
              Desde el inicio del plan
            </label>
          </div>
        </div>

        <div className="base-data-card default-data-card-bg u-marginAm u-displayFlex ">
          <div className="card-icon-container">
            <BarChartIcon />
          </div>

          <div className="u-felxColumn u-marginHm">
            <label className="card-label u-color-title-card">
              Valor Inicial 2021
            </label>
            <label className="card-price">U$D 7.303.451.-</label>
          </div>
        </div>

        <div className="base-data-card default-data-card-bg u-marginAm u-displayFlex">
          <div className="card-icon-container">
            <AttachMoneyIcon />
          </div>
          <div className="u-felxColumn u-marginHm">
            <label className="card-label u-color-title-card">
              Valor a principio de año
            </label>
            <label className="card-price">U$D 7.303.451.-</label>
          </div>
        </div>

        <div className="base-data-card default-data-card-bg u-marginAm u-displayFlex">
          <div className="u-felxColumn">
            <label className="card-label u-color-title-card u-displayFlex u-justifySpaceBetween">
              Rendimiento YTD $
              <div>
                <DarkToolTip
                  content={`YTD "Year to Date" Desde Enero este año `}
                  placement={"top"}
                />
              </div>
            </label>
            <label className="card-price">
              U$D 7.303.451.-
              <span className="u-color-success">
                <TrendingUpIcon />
              </span>
            </label>
          </div>
        </div>
      </div>

      <div className="u-displayFlex subSectionTwo-container ">
        <div className="base-data-card default-data-card-bg u-marginAm u-displayFlex u-Width100">
          <div className="u-flexColumn u-Width100">
            <h3 className="u-color-bright-blue card-label">
              Rendimiento Histórico
            </h3>
            <div className="main-chart-container u-marginAs ">
              <CustomLineChart />
            </div>
          </div>
        </div>

        <div>
          {" "}
          <div className="u-felxColumn">
            <div className="base-data-card default-data-card-bg u-marginAm u-displayFlex ">
              <div className="u-felxColumn">
                <label className="card-label u-color-title-card u-displayFlex u-justifySpaceBetween">
                  Rendimiento YTD %
                  <DarkToolTip
                    content={`YTD "Year to Date" Desde Enero este año `}
                    placement={"top"}
                  />
                </label>
                <label className="card-price">
                  U$D 7.303.451.-
                  <span className="u-color-success">
                    <TrendingUpIcon />
                  </span>
                </label>
              </div>
            </div>

            <div className="base-data-card seccondary-data-card-side-bg u-marginAm cash-card">
              <div className="u-felxColumn">
                <div className="seccondary-card-icon-container u-alignSelfEnd u-marginBs">
                  <WalletOutlinedIcon />
                </div>
                <label className="card-label">
                  Cash Disponible para invertir
                </label>
                <label className="card-price u-paddingBm">
                  U$D 7.303.451.-
                </label>
              </div>
            </div>
            <div className="base-data-card seccondary-data-card-side-bg u-marginAm cash-card">
              <div className="u-felxColumn">
                <div className="seccondary-card-icon-container u-alignSelfEnd u-marginBs">
                  <AttachMoneyIcon />
                </div>
                <label className="card-label">Cash invertido</label>
                <label className="card-price u-paddingBm">
                  U$D 7.303.451.-
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Seccion 2 */}

      <div className="u-displayFlex u-flexWrap u-marginAm">
        <div className="base-data-card default-data-card-bg u-marginAm u-displayFlex actives-diversification-donutchart">
          <div className="u-felxColumn u-Width100">            
            <div>
              <CardTitleWSelect customTitle="Diversificación de Activos" />
              {brandName} conservadora
            </div>
            <DonutChart />
          </div>
        </div>
        {/* Falta hacer Grafico para esta seccion */}
        <div className="base-data-card default-data-card-bg u-marginAm u-displayFlex participants-evolution-barchart">
          <div className="u-felxColumn u-Width100">
                <div className="u-color-title-card-secondary">
                  <CardTitleWSelect customTitle="Evolución de Participantes" />
                  <label>
                    60% de adheridos desde 2021
                  </label>
                </div>
                <HorizontalBarsChart/>
          </div>
        </div>
      </div>
    </div>
  );
}
