import React from "react";
import MainDashboard from "../Dashboard/MainDashboard";
import MainPortfolio from "../Portfolio/MainPortfolio.js";
import MainUsuarios from "../Usuarios/MainUsuarios.js";
import { SideNav } from "../../components/SideNav/SideNav.js";
import "./Layout.scss";

export default function Layout({ page }) {
  const handleToggleView = (page) => {
    if (page === "dashboard") {
      return <MainDashboard />;
    }
    if (page === "portfolio") {
      return <MainPortfolio />;
    }
    if (page === "usuarios") {
      return <MainUsuarios />;
    }
  };

  return (
    <div className='u-displayGrid primary-view'>
        <SideNav pageTitle={page}/> 
      <div>
        {handleToggleView(page)}
      </div>
    </div>
  );
}
