import React from "react";
import "./MainDashboard.scss";
import SubSectionTwo from "./subSectionTwo/subSectionTwo";
import SubSectionThree from "./subSectionThree/subSectionThree";
import SubSectionOne from "./subSectionOne/subSectionOne";
import HeaderBar from "../../components/HeaderBar/HeaderBar";
import MobileBottomBar from "../../components/MobileBottomBar/MobileBottomBar";
//import { SideNav } from '../../components/SideNav/SideNav.js';

export default function MainDashboard() {
  return (
    <div className="u-displayGrid section-two-main main-dashboard ">
      <div className="dashboard-header">
        <HeaderBar
          headerTitle={"Dashboard"}
          headerTitleInfo={"Vision General de Planes de Pension"}
        />
      </div>
      <div className="">
        <SubSectionOne />
      </div>
      <div className="">
        <SubSectionTwo />
      </div>
      <div className="">
        <SubSectionThree />
      </div> 
      <div>
        <MobileBottomBar />
      </div>
    </div>
  );
}
