import React from "react";
import BarChartIcon from "@mui/icons-material/BarChart";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import WalletOutlinedIcon from "@mui/icons-material/WalletOutlined";
//to be used in future logic
//import TrendingDownIcon from '@mui/icons-material/TrendingDown';
//import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CustomLineChart from "../../../components/MultipleLinesChart/MultipleLinesChart";
import DarkToolTip from "../../../components/ToolTip/DarkToolTip";

import "./subSectionOne.scss";
import CardTitleWSelect from '../../../components/CardTitleWSelect/CardTitleWSelect';

export default function SubSectionOne() {
  let currentPercentageValue = 0;

  return (
    <div>
      <div className="u-displayFlex u-flexWrap u-marginAm">
      <div className="base-data-card seccondary-data-card-bg u-marginAm mobile-card">
        <div className='u-felxColumn'>
            <label className='card-label'>Valor Actual de la cartera</label>
            <label className='card-price'>U$D 7.303.451.-</label>
            <label className='card-sub-label'>
                <span className='u-color-success u-marginRs'>
                    {currentPercentageValue} %
                </span>                
                Desde el inicio del plan</label>
        </div>        
      </div>
      <div className="base-data-card default-data-card-bg u-marginAm u-displayFlex mobile-card">
        <div className='card-icon-container'>
            <BarChartIcon/>
        </div>
        
        <div className='u-felxColumn u-marginHm'>
            <label className='card-label u-color-title-card'>Valor Inicial 2021</label>
            <label className='card-price'>U$D 7.303.451.-</label>
        </div>        
      </div>
      <div className="base-data-card default-data-card-bg u-marginAm u-displayFlex mobile-card">
        <div className='card-icon-container'>
                <AttachMoneyIcon/>
        </div>
        <div className='u-felxColumn u-marginHm'>
            <label className='card-label u-color-title-card'>Valor a principio de año</label>
            <label className='card-price'>U$D 7.303.451.-</label>
        </div>        
      </div>

        <div className="base-data-card default-data-card-bg u-marginAm u-displayFlex non-mobile-data">
          <div className="u-felxColumn">
            <label className="card-label u-color-title-card u-displayFlex u-justifySpaceBetween">
              Rendimiento YTD $
              <div>
                <DarkToolTip
                  content={`YTD "Year to Date" Desde Enero este año `}
                  placement={"top"}
                />
              </div>
            </label>
            <label className="card-price">
              U$D 7.303.451.-
              <span className="u-color-success">
                <TrendingUpIcon />
              </span>
            </label>
          </div>
        </div>
        <div className="base-data-card default-data-card-bg u-marginAm u-displayFlex mobile-card mobile-card-seccondary">
          <div className="u-felxColumn">
            <label className="card-label u-color-title-card u-displayFlex u-justifySpaceBetween">
              Rendimiento YTD %
              <DarkToolTip
                content={`YTD "Year to Date" Desde Enero este año `}
                placement={"top"}
              />
            </label>
            <label className="card-price">
              1.05%
              <span className="u-color-success">
                <TrendingUpIcon />
              </span>
            </label>
          </div>
        </div>
      </div>
      <div className="u-displayFlex u-flexWrap u-marginAm dashboard-second-line u-alignItems">
        <div className="base-data-card default-data-card-bg u-marginAm u-displayFlex areaLine-graph-card">
          <div className='u-flexColumn u-Width100'>
              <div className='u-displayFlex u-alignItems'>
                <div>
                  <h3 className='u-color-bright-blue card-label'>Rendimiento Histórico</h3>
                  <div className='u-marginRm-12 mobile-display'>
                      <svg width="25" height="7" className='u-marginHs'>
                        <g>
                          <circle cx="3.5" cy="3.5" r="3.5" fill="#0096D7"></circle>
                        </g>
                      </svg>          
                      <span>Rendimiento Acumulado</span>
                  </div>
                </div>
                
                <span className='u-marginHm-12 non-mobile-data'>Valor en USD  |</span>
                <div className='u-displayFlex u-alignItems'>
                  <div className='u-marginRm-12 non-mobile-data'>
                    <svg width="25" height="7" className='u-marginHs'>
                      <g>
                        <circle cx="3.5" cy="3.5" r="3.5" fill="#0096D7"></circle>
                      </g>
                    </svg>          
                    <span>Rendimiento Acumulado</span>
                  </div>

                  <div className='u-marginRm-12 non-mobile-data'>
                    <svg width="25" height="7" className='u-marginHs'>
                      <g>
                        <circle cx="3.5" cy="3.5" r="3.5" fill="#4AA785"></circle>
                      </g>
                    </svg>          
                    <span>Aportes Participantes</span>
                  </div>

                  <div className='u-marginRm-12 non-mobile-data'>
                    <svg width="25" height="7" className='u-marginHs'>
                      <g>
                        <circle cx="3.5" cy="3.5" r="3.5" fill="#F14D4D"></circle>
                      </g>
                    </svg>          
                    <span>Aportes Empresa</span>
                  </div>
                  <CardTitleWSelect customTitle={null}/>
                </div>                
              </div>
              
              <div className='main-chart-container u-marginAm'>
                <CustomLineChart/>
              </div>              
          </div>
        </div>
        <div className="u-felxColumn wallet-cards">
          <div className="base-data-card seccondary-data-card-side-bg u-marginAm cash-card">
            <div className="u-felxColumn cash-card-direction">
              <div className="seccondary-card-icon-container u-alignSelfEnd u-marginBs">
                <WalletOutlinedIcon />
              </div>
              <div className="u-felxColumn u-marginLm-12">
                <label className="card-label">
                  Cash Disponible para invertir
                </label>
                <label className="card-price u-paddingBm">U$D 7.303.451.-</label>
              </div>              
            </div>
          </div>
          <div className="base-data-card seccondary-data-card-side-bg u-marginAm cash-card">
            <div className="u-felxColumn cash-card-direction">
              <div className="seccondary-card-icon-container u-alignSelfEnd u-marginBs">
                <AttachMoneyIcon />
              </div>
              <div className="u-felxColumn u-marginLm-12">
                <label className="card-label">Cash invertido</label>
                <label className="card-price u-paddingBm">U$D 7.303.451.-</label>
              </div>              
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
