import axios from "axios";

const PENSION_DEV_URL = "https://pensiondev.lynxsit.com";

export async function logIn(email, password) {
  try {
    const response = await axios.post(`${PENSION_DEV_URL}/v1/api/user/login`, {
      email,
      password,
    });
    const data = response.data;
    sessionStorage.setItem("CURRENT_USER", JSON.stringify(data));
    sessionStorage.setItem("isAuthenticated", "true");
    return true;
  } catch (error) {
    //COMENTADO POR AHORA PARA PROBAR SI SE CAE EL HOST
    // console.error("There was an error with the login request:", error);
    // return null;
    const data = {
      id: "4",
      token: "sjdaisdasuihdpaus",
    };
    sessionStorage.setItem("CURRENT_USER", JSON.stringify(data));
    sessionStorage.setItem("isAuthenticated", "true");
    return true;
  }
}

export function checkAuthentication() {
  const currentUser = sessionStorage.getItem("CURRENT_USER");
  return !!currentUser;
}

export function logOut() {
  sessionStorage.removeItem("CURRENT_USER");
  sessionStorage.removeItem("isAuthenticated");
}
