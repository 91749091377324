import React, { useEffect, useState } from "react";
import "./Login.scss";
import criteriaLogo from "../../assets/logo-lower-opacity.svg";
import { ActionButton } from "../../components/ActionButton/ActionButton";
import { InputPass } from "../../components/InputPass/InputPass";
import { useTranslation } from "react-i18next";
import { logIn } from "../../services/AuthService";
import { useNavigate } from "react-router-dom";

export default function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const { i18n } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    i18n.changeLanguage(navigator.language);
  }, [i18n]);

  const handleLogin = async (e) => {
    e.preventDefault();
    const user = await logIn(email, password);

    if (user) {
      navigate('/dashboard');
      window.dispatchEvent(new Event('storage')); 
    } else {
      setErrorMessage("Invalid email or password. Please try again.");
    }
  };

  return (
    <div className="sup-main-container">
      <div className="main-container">
        <div className="container">
          <div className="titulo-text">
            <img src={criteriaLogo} alt="Criteria" className="logo" />
          </div>
          <div className="form-container">
            <div className="login-card u-background-light-blue">
              <h1 className="u-marginBs">Sign In</h1>
              <span className="u-color-white u-marginBl">
                Bienvenidos a Criteria Pension & Benefits
              </span>
              <span className="text-divider">Ingresa tus datos</span>
              <form className="u-felxColumn u-marginVm" onSubmit={handleLogin}>
                <InputPass
                  valueInput={email}
                  setValue={setEmail}
                  placeholder="Ingresa tu email"
                  inputType="email"
                  inputBackground="u-transparent-white-background"
                  inputColor="u-color-lightest-blue"
                  placeholderColor="u-placeholder-lightest-blue"
                />
                <InputPass
                  valueInput={password}
                  setValue={setPassword}
                  placeholder="Ingresa tu nueva contraseña"
                  inputType="password"
                  inputBackground="u-transparent-white-background"
                  inputColor="u-color-lightest-blue"
                  placeholderColor="u-placeholder-lightest-blue"
                />
                {errorMessage && (
                  <div className="error-message">{errorMessage}</div>
                )}
                <div className="forgot-password">
                  <a href="/forgot-password">¿Olvidaste tu contraseña?</a>
                </div>
                <ActionButton
                  buttonType="submit"
                  buttonText="Ingresar"
                  buttonColor="u-color-dark-blue"
                  buttonBackground="u-fog-white-background"
                />
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="bottom-container">
        @ 2024 Criteria | Pension & benefits
      </div>
    </div>
  );
}
