import React from "react";
import "./HeaderSearchBar.scss";
import SearchIcon from "@mui/icons-material/Search";

export const HeaderSearchBar = () => {
  return (
    <div className="SearchBar">
      <div className="search u-displayFlex">
        <SearchIcon
          className="search-icon"
          sx={{
            height: "24px",
            backgroundColor: "white",
          }}
        />
        <input className="searchBar-Input" type="text" placeholder="Buscar" />
      </div>
    </div>
  );
};
