import React from "react";
import "./subSectionOne.scss";
//iconos
import AttachMoneyRoundedIcon from "@mui/icons-material/AttachMoneyRounded";
import ListAltRoundedIcon from "@mui/icons-material/ListAltRounded";
import PeopleAltRoundedIcon from "@mui/icons-material/PeopleAltRounded";
import CurrencyExchangeOutlinedIcon from "@mui/icons-material/CurrencyExchangeOutlined";
import ListAltIcon from "@mui/icons-material/ListAlt";
// MUI components
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
// components
import { CardOneItems } from "../../../components/SubSectionTwo/CardOne/CardOneItems";

export default function SubSectionOne() {
  return (
    <div className=" ">
      {/* card one*/}
      <div className="base-data-card default-data-card-bg u-marginAm ">
        <div className="u-felxColumn u-justifySpaceBetween u-marginHm">
          <div className="u-displayFlex u-marginRm ">
            <div>
              <ListAltIcon />
            </div>
            <div className="card-label u-Width100 u-color-bright-blue">
              Portfolios
            </div>
          </div>

          <div>
            <FormGroup>
              <FormControlLabel
                control={<Checkbox />}
                label="Sygenta - Conservadora"
              />
              <FormControlLabel
                control={<Checkbox />}
                label="Sygenta -  Conservadora"
              />
              <FormControlLabel
                control={<Checkbox />}
                label="Sygenta - Moderada"
              />
            </FormGroup>
          </div>
        </div>
      </div>

      {/* card two */}
      {/* Usando components del dashboard */}
      <div className="base-data-card default-data-card-bg u-marginAm ">
        <div className="card-label u-Width100 u-color-bright-blue">
          Movimientos de cartera
        </div>
        <div className="card-items u-Width100 u-displayFlex">
          <CardOneItems
            icon={<AttachMoneyRoundedIcon className="sub-card-icon" />}
            descripcion={"Depositos"}
            value={720.129}
            valueType={"U$D"}
            bg={"default-data-card-bg"}
          />
          <CardOneItems
            icon={<CurrencyExchangeOutlinedIcon className="sub-card-icon" />}
            descripcion={"Extracciones"}
            value={750.606}
            valueType={"U$D"}
            bg={"default-data-card-bg"}
          />
          <CardOneItems
            icon={<ListAltRoundedIcon className="sub-card-icon" />}
            descripcion={"Valor de cuota parte"}
            value={27.473}
            valueType={"U$D"}
            bg={"default-data-card-bg"}
          />
          <CardOneItems
            icon={<PeopleAltRoundedIcon className="sub-card-icon" />}
            descripcion={"Participantes Adheridos"}
            value={180}
            valueType={""}
            bg={"default-data-card-bg"}
          />
          <CardOneItems
            icon={<ListAltIcon className="sub-card-icon" />}
            descripcion={"NAV"}
            value={95}
            valueType={""}
            bg={"default-data-card-bg"}
          />
        </div>
      </div>
    </div>
  );
}
