import React, { useState } from "react";
import "./ProfileDropDownMenu.scss";
import { logOut } from "../../services/AuthService";

export default function ProfileDropDownMenu() {
  const [openMenu, setOpenMenu] = useState(false);

  const handleLogOut = () => {
    logOut();
    console.log("Log Out...");
  };

  return (
    <div className="profileMenu">
      <i onClick={() => setOpenMenu((prev) => !prev)}>
        <img
          className="profile_Icon"
          src="https://avatar.iran.liara.run/public/32"
          alt="Profile_avatar"
        />
      </i>

      {openMenu && (
        <div className="dropDownMenu">
          <ul>
            <div className="u-displayFlex dropDownOptions">
              <div onClick={handleLogOut} className="text-black">
                LOG OUT
              </div>
            </div>
          </ul>
        </div>
      )}
    </div>
  );
}
