import { PieChart } from '@mui/x-charts/PieChart';
import * as React from 'react';
import './DonutChart.scss';
import Box from '@mui/material/Box';

const data = [
  { value: 5, label: 'Renta Fija' },
  { value: 7, label: 'Renta Variable' },
  { value: 50, label: 'Efectivo' },
];

export default function DonutChart() {
  return (
    <Box sx={{ width: '100%' }}>
      <PieChart
        height={250}
        colors={['#A1E3CB', '#85D1EF', '#042E5D']}
        series={[
          {
            data,
            innerRadius: 50,
            outerRadius: 100,
            paddingAngle: 2,
            cornerRadius: 5,
            startAngle: -50,
            endAngle: 310,
            cx: 100,
            cy: 100,
          }
        ]}
      />
    </Box>
  );
}
