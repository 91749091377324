import React from "react";
import "./subSectionOne.scss";
//import CardTitleWSelect from "../../../components/CardTitleWSelect/CardTitleWSelect";
//icons
import SearchIcon from "@mui/icons-material/Search";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import BarChartIcon from "@mui/icons-material/BarChart";
//graphs
import CustomLineChart from "../../../components/MultipleLinesChart/MultipleLinesChart";
import DonutChart from "../../../components/DonutChart/DonutChart";
import HorizontalBarsChart from "../../../components/HorizontalBarsChart/HorizontalBarsChart";

export default function SubSectionOne() {
  let currentPercentageValue = 0;
  let matchingPercentageValue = "2.00";
  let brandName = "Syngenta"
  return (
    <div className="">
      <div className="u-felxColumn">
        {/* Search bar */}
        <div className="u-displayFlex u-justifyBetween u-alignItems gap4 u-marginHl ">
          <div className=" u-displayFlex u-Width100 base-data-card default-data-card-bg ">
            <SearchIcon
              className="search-icon"
              sx={{
                height: "20px",
              }}
            />
            <input
              className="searchBar-Input"
              type="text"
              placeholder="Ingresa el DNI del participante"
            />
          </div>
          <div className="non-mobile-data">
            <FilterAltOutlinedIcon
              sx={{ fontSize: "40px", cursor: "pointer" }}
            />
          </div>
        </div>
        {/* Cards */}
        <div className="u-displayFlex u-marginAm u-justifyEvenly mobile-flexWrap  ">
          <div className="base-data-card seccondary-data-card-bg u-marginAm u-justifyCenter u-Width100">
            <div className="u-felxColumn ">
              <label className="card-label">Valor Actual de la cartera</label>
              <label className="card-price">U$D 7.303.451.-</label>
              <label className="card-sub-label">
                <span className="u-color-success u-marginRs">
                  {currentPercentageValue} %
                </span>
                Desde el inicio del plan
              </label>
            </div>
          </div>
          <div className="base-data-card tertiary-data-card-side-bg u-marginAm u-justifyCenter u-Width100">
            <div className="u-felxColumn">
              <label className="card-label">Aportes totales</label>
              <label className="card-price">U$D 240,9.-</label>
            </div>
          </div>
          <div className="base-data-card default-data-card-bg u-marginAm u-justifyCenter u-Width100">
            <div className="u-displayFlex gap4">
              <div className="card-icon-container">
                <BarChartIcon />
              </div>
              <div className="u-felxColumn">
                <label className="card-label">Aporte desde incial</label>
                <label className="card-price">U$D 108,82.-</label>
              </div>
            </div>
          </div>
          <div className="base-data-card default-data-card-bg u-marginAm u-justifyCenter u-Width100">
            <div className="u-displayFlex gap5">
              <div className="card-icon-container">
                <BarChartIcon />
              </div>
              <div className="u-felxColumn ">
                <label className="card-label ">% Matching</label>
                <label className="card-price">{matchingPercentageValue}%</label>
              </div>
            </div>
          </div>
        </div>
        {/* Graph 1 */}
        <div className="u-marginAm u-Width100">
          <div className="base-data-card default-data-card-bg u-marginAm u-displayFlex historic-custom-lines-chart">
            <div className="u-flexColumn">
            <div className='u-displayFlex u-alignItems'>
                <div>
                  <h3 className='u-color-bright-blue card-label'>Rendimiento Histórico</h3>
                  <div className='u-marginRm-12 mobile-display'>
                      <svg width="25" height="7" className='u-marginHs'>
                        <g>
                          <circle cx="3.5" cy="3.5" r="3.5" fill="#0096D7"></circle>
                        </g>
                      </svg>          
                      <span>Rendimiento Acumulado</span>
                  </div>
                </div>
                
                <span className='u-marginHm-12 non-mobile-data'>Valor en USD  |</span>
                <div className='u-displayFlex u-alignItems'>
                  <div className='u-marginRm-12 non-mobile-data'>
                    <svg width="25" height="7" className='u-marginHs'>
                      <g>
                        <circle cx="3.5" cy="3.5" r="3.5" fill="#0096D7"></circle>
                      </g>
                    </svg>          
                    <span>Rendimiento Acumulado</span>
                  </div>

                  <div className='u-marginRm-12 non-mobile-data'>
                    <svg width="25" height="7" className='u-marginHs'>
                      <g>
                        <circle cx="3.5" cy="3.5" r="3.5" fill="#4AA785"></circle>
                      </g>
                    </svg>          
                    <span>Aportes Participantes</span>
                  </div>

                  <div className='u-marginRm-12 non-mobile-data'>
                    <svg width="25" height="7" className='u-marginHs'>
                      <g>
                        <circle cx="3.5" cy="3.5" r="3.5" fill="#F14D4D"></circle>
                      </g>
                    </svg>          
                    <span>Aportes Empresa</span>
                  </div>
                  
                </div>                
              </div>
              <div className=" u-marginAm">
                <CustomLineChart />
              </div>
            </div>
          </div>
        </div>

        {/* Graph 2 & 3 */}
        <div className="u-displayFlex">
          <div className="u-displayFlex u-flexWrap u-marginAm ">
            <div className="base-data-card default-data-card-bg u-marginAm u-displayFlex u-marginLm diversification-donutchart">
              <div className="u-felxColumn u-Width100">
                <div>
                  <h3 className="card-label u-color-title-card-secondary">Diversificación de Activos</h3>
                  {brandName} conservadora
                </div>
                <DonutChart />
              </div>
            </div>
            {/* Falta hacer Grafico para esta seccion */}
            <div className="base-data-card default-data-card-bg u-marginAm u-displayFlex contributions-barchart ">
              <div className="u-felxColumn u-Width100">
                <div>
                  <h3 className="card-label u-color-title-card-secondary">Aportes Empresa + Participante</h3>
                  <label className="card-label">
                    Aportes totales USD 240,9
                  </label>
                </div>
                <HorizontalBarsChart />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
