import React from "react";
import "./MobileBottomBar.scss";

//icons
import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import PieChartIcon from "@mui/icons-material/PieChart";
import WebAssetIcon from "@mui/icons-material/WebAsset";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";

export default function MobileBottomBar() {
  return (
    <div className="mobileOnly u-Width100">
      <div className="u-flexColumn bottomBarFixed  u-paddingAl  ">
        <div className="u-displayFlex u-justifySpaceBetween">
          <i>
            <AutoStoriesIcon sx={{ color: "white" }} />
          </i>
          <i>
            <FolderOpenIcon sx={{ color: "white" }} />
          </i>
          <i>
            <div className="u-felxColumn u-textAlignCenter u-alignItems">
              <PieChartIcon sx={{ color: "white" }} />
              <div className="text-white">Vision General</div>
            </div>
          </i>
          <i>
            <WebAssetIcon sx={{ color: "white" }} />
          </i>
          <i>
            <SettingsOutlinedIcon sx={{ color: "white" }} />
          </i>
        </div>
        <svg width="100%" height="4">
          <g>
            <rect width="100%" height="7" fill="#c2c1c0" rx="5"></rect>
          </g>
        </svg>
      </div>
    </div>
  );
}
